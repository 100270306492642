import React, { useState } from "react";
import Style from "./feedback.module.css";
import TextField from "@mui/material/TextField";
import { createFeedback } from "../../service/feedback";
import Header from "../header/Header";
import { Box, Container } from "@mui/material";
import UserNotLoggedIn from "../../common/UserNotLoggedIn";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { capitalizeFirstLetter } from "../../helper/capitalise";

const Feedback = () => {
  const getCurrentUserSelector = useSelector((state) => state?.getCurrentUser);
  const { error } = getCurrentUserSelector;

  const [data, setData] = useState({
    name: "",
    email: "",
    feedback: "",
  });
  const [errors, setErrors] = useState({ name: "", email: "", feedback: "" });
  const [userNotLoggedIn, setUserNotLoggedIn] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  function isEmail(email) {
    // Regular expression pattern for email validation
    const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;

    // Test the email against the regex pattern and return the result
    return emailRegex.test(email);
  }

  const handleSubmit = () => {
    if (error) {
      return setUserNotLoggedIn(true);
    }
    
    // Perform validation before proceeding
    if (!validate(data)) {
      return;
    }
  
    createFeedback(data).then((res) => {
      if (res.data.result) {
        setData({
          name: "",
          email: "",
          feedback: "",
        });
      }
    });
  };
  
  const validate = (data) => {
    let flg = true; // Flag to track if the form is valid
    const obj = {}; // Object to store error messages
  
    // Validate each field
    Object.keys(data).forEach((key) => {
      if (data[key] === "" || !data[key]) {
        flg = false;
        obj[key] = `${capitalizeFirstLetter(key)} cannot be empty`;
      }
    });
  
    // Additional validation for email
    if (data.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
      flg = false;
      obj.email = "Please enter a valid email address";
    }
  
    // Set error messages if any errors were found
    setErrors(obj);
  
    // Return the validation flag
    return flg;
  };
  

  return (
    <>
      <Box className={Style.main}>
        <Header />
        <Container>
          <div className={Style.container}>
            <div className={Style.contained}>
              <h1>Feedback</h1>
              <div className={Style.text}>
                <TextField
                  sx={{ width: "100%" }}
                  className={Style.textfield}
                  id="outlined-basic"
                  label="Name *"
                  variant="outlined"
                  name="name"
                  value={data.name}
                  onChange={handleChange}
                />
                <span
                  style={{ fontSize: "12px", color: "red", paddingLeft: "2px" }}
                >
                  {errors.name}
                </span>
              </div>
              <div className={Style.text}>
                <TextField
                  sx={{ width: "100%" }}
                  className={Style.textfield}
                  id="outlined-basic"
                  label="Email Address *"
                  variant="outlined"
                  name="email"
                  type="email"
                  value={data.email}
                  onChange={handleChange}
                />
                <span
                  style={{ fontSize: "12px", color: "red", paddingLeft: "2px" }}
                >
                  {errors.email}
                </span>
              </div>
              <div className={Style.text}>
                <TextField
                  sx={{ width: "100%" }}
                  className={Style.textfield}
                  id="outlined-basic"
                  label="Feedback *"
                  variant="outlined"
                  name="feedback"
                  value={data.feedback}
                  multiline
                  rows={10}
                  onChange={handleChange}
                />
                <span
                  style={{ fontSize: "12px", color: "red", paddingLeft: "2px" }}
                >
                  {errors.feedback}
                </span>
              </div>
              <div onClick={handleSubmit} className={Style.btn_box}>
                Submit
              </div>
              <p>or</p>
              <p>Write to us at eduthum@applore.in</p>
            </div>
          </div>
        </Container>
      </Box>
      {userNotLoggedIn ? (
        <UserNotLoggedIn open={userNotLoggedIn} setOpen={setUserNotLoggedIn} />
      ) : null}
    </>
  );
};
export default Feedback;
