import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpService from "../../config/authAxios";

const initialState = { loading: false, result: null, error: null, message: "" };

export const moreLikeCLBsAPI = createAsyncThunk(
  "more-like-clbs",
  async (data, { rejectWithValue }) => {
    console.log(data,"more");
    try {
      if (!data) return;
      const response = await HttpService.get(`/clbs/more-like?ownerId=${data.ownerId}&clbId=${data.clbId}`);
      return await response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const counterSlice = createSlice({
  name: "more-like-clbs",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(moreLikeCLBsAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(moreLikeCLBsAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload?.result;
        state.message = action.payload?.message;
      })
      .addCase(moreLikeCLBsAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        state.message = action.payload?.message;
      });
  },
});

const moreLikeCLBsReducer = counterSlice.reducer;

export default moreLikeCLBsReducer;
