import { Divider } from '@mui/material'
import React from 'react'

export default function DividerUI({ orientation, bgcolor, height }) {
    return (
        <Divider
            orientation={orientation ? orientation : 'horizontal'}
            sx={{
                my: 2, bgcolor: bgcolor ? bgcolor : '#fff',
                height: height ? height : null
            }}
        />
    )
}
