import { Button, Card, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { verifyEmailAPI } from "../../redux/user/verifyEmail";
import Snack from "../../ui/Snack";
import Style from "../../styles/auth.module.scss";

export default function Verify() {
  const verifyEmailSelector = useSelector((state) => state?.verifyEmail);
  const { message, error } = verifyEmailSelector;

  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleVerifyEmail = async () => {
    const verifyEmailData = await dispatch(verifyEmailAPI(token));
    if (!verifyEmailData?.payload?.error) {
      localStorage.setItem(
        "accessToken",
        verifyEmailData?.payload?.result?.accessToken
      );
      navigate("/");
      window.location.reload();
    }
  };

  return (
    <>
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        <Grid item lg={4} md={4} sm={5} xs={12}>
          <Grid lg={5} md={7} sm={9} xs={12}>
            <Card>
              <Stack spacing={2} alignItems="center">
                <img
                  src="https://gifdb.com/images/high/black-background-yes-check-mark-n7uhp9prauejba0a.webp"
                  style={{ height: "100%" }}
                  alt=""
                />
                <Typography variant="h4">Verify Email!</Typography>
                <Typography variant="p">
                  Hi! Use the button below to verify your email and start
                  enjoying eduthum!
                </Typography>
                <Button
                  onClick={handleVerifyEmail}
                  fullWidth
                  variant="contained"
                >
                  Verify Email
                </Button>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
