import React, { useEffect, useState } from "react";
import styles from "./collection.module.css";
import MenuItem from "@mui/material/MenuItem";
import { getAllCollection } from "../../service/collection";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ClickAwayListener, makeStyles } from "@material-ui/core";
import { Fade, List, ListItem, Paper, Popper, TextField } from "@mui/material";
import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  poper: {
    top: "10px !important",
    zIndex: "100",
    left: "-10px !important",
    fontFamily: "DM Sans",
    fontStyle: "normal",
    maxHeight: "50vh",
  },
  boxsrt: {
    width: "200px",
    height: "fit-content",
    background: "#050505",
    color: "white",
    fontWeight: "700",
    cursor: "pointer",
    " & h3": {
      fontSize: "16px",
      // color: "#82F9FD",
    },
    " & p": {
      fontSize: "16px",
      fontWeight: "500",
    },
  },
}));

const Collected = () => {
  const navigate = useNavigate();
  const [collect, setCollect] = useState();
  const [age, setAge] = React.useState();
  const [sort, setSort] = useState();
  const classes = useStyles();
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  useEffect(() => {
    getAllCollection(age).then((res) => {
      setCollect(res.data.result);
    });
  }, [age]);

  const hanldeClickSort = (event) => {
    setSort(sort ? null : event.currentTarget);
  };
  const handleSort = (check) => {
    setAge(check);
    hanldeClickSort();
  };
  const open1 = Boolean(sort);
  return (
    <div
      style={{
        background: `url(/images/background/Form.png) 100% 100% `,
        backgroundPosition: "center",
        backgroundSize: "100vw ",
      }}
    >
      <div className={styles.container}>
        <div className={styles.contained}>
          <div className={styles.head}>
            <h1>Collections</h1>
            <div style={{ marginLeft: "50px" }}>
              {/* <TextField
                sx={{ width: "100%" , color : "red"}}
                className={styles.textfield}
                id="outlined-basic"
                label="Sort"
                variant="outlined"
                name="category"
                select
                onChange={handleChange}
                value={age}
              >
                <MenuItem value="Last Month" >Last Month</MenuItem>
                <MenuItem value="Week">Week</MenuItem>
                <MenuItem value="Day">Day</MenuItem>
              </TextField> */}
              <p>
                Sort <SortOutlinedIcon onClick={hanldeClickSort} />
              </p>
              <Popper
                id={open1 ? "simple-popper" : undefined}
                open={open1}
                anchorEl={sort}
                transition
                // className={classes.poper}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    {/* <ClickAwayListener > */}
                    <Paper>
                      <List className={classes.boxsrt}>
                        <ListItem>
                          <div className={styles.bxsrt}>
                            <p onClick={() => handleSort("")}>All</p>
                            <p onClick={() => handleSort("Last Month")}>
                              Last Month
                            </p>
                            <p onClick={() => handleSort("Week")}>Week</p>
                            {/* <p onClick={() => handleSort("discounts")}>
                              Discounts
                            </p> */}
                            <p onClick={() => handleSort("Day")}>Day</p>
                            <p onClick={() => handleSort("hightolow")}>
                              Ranking : High to low
                            </p>
                          </div>
                        </ListItem>
                      </List>
                    </Paper>
                    {/* </ClickAwayListener> */}
                  </Fade>
                )}
              </Popper>
            </div>
          </div>
          <div className="top_collectors_section">
            {collect &&
              collect.map((ele) => {
                return (
                  <div
                    className="collectors_card"
                    onClick={() => navigate(`/collection/${ele._id}`)}
                  >
                    <div className="collectors_card_head">
                      <img src={ele.cover} alt="" />
                    </div>
                    <div className="collectors_card_profile">
                      <img src={ele.picture} alt="" />
                    </div>
                    <div className="collectors_card_details">
                      <h3>{ele.name}</h3>
                      <h4>Rs. {ele.price}</h4>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Collected;
