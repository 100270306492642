import { Label } from "@material-ui/icons";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React from "react";
import Header from "../../components/header/Header";
import Style from "../../styles/tickets.module.scss";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { raiseTicketAPI } from "../../redux/ticket/raiseTicket";
import toast from "react-hot-toast";

const CreateTicket = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    subject: "",
    description: "",
  });
  const dispatch = useDispatch();

  function isEmail(email) {
    const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  const handleData = (event) => {
    const { value, name } = event.target;
    setData({ ...data, [name]: value });
  };

  const handleTicket = (event) => {
    event.preventDefault();
    if (!isEmail(data.email))
      return toast.error("Proper Email is required", {
        duration: 5000,
        position: "bottom-left",
      });
    dispatch(raiseTicketAPI(data));
    setData({ name: "", email: "", subject: "", description: "" });
  };

  const { name, email, subject, description } = data;

  return (
    <>
      <Box className={Style.main}>
        <Header />
        <Box>
          <Grid container justifyContent="center" sx={{ p: 2, mt: 10 }}>
            <Grid lg={5} md={7} sm={9} xs={12}>
              <Card>
                <form onSubmit={handleTicket}>
                  <Stack spacing={4}>
                    <Stack alignItems="center" spacing={1}>
                      <Typography color="primary" variant="h5">
                        Raise Ticket
                      </Typography>
                    </Stack>
                    <FormControl>
                      <TextField
                        value={name}
                        name="name"
                        type="text"
                        required
                        onChange={handleData}
                        label="Name"
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        value={email}
                        name="email"
                        type="email"
                        required
                        onChange={handleData}
                        label="Email"
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        value={subject}
                        name="subject"
                        type="text"
                        required
                        onChange={handleData}
                        label="Subject"
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        value={description}
                        name="description"
                        type="text"
                        required
                        onChange={handleData}
                        label="Description"
                      />
                    </FormControl>
                    <Button type="submit" variant="contained">
                      Raise Now
                    </Button>
                  </Stack>
                </form>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default CreateTicket;
