import {
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Avatar,
  Box,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTopCollectionsAPI } from "../../redux/collection/getTopCollections";
import DividerUI from "../../ui/DividerUI";
import LoaderUI from "../../ui/LoaderUI";
import FilterListIcon from "@mui/icons-material/FilterList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment/moment";
import { Empty } from "../../helper/component";

const TopCollectors = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState("Last Month");
  const [timeStamp, setTimeStamp] = useState({
    startDate: moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
    endDate: moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD"),
  });
  const getTopCollectionsSelector = useSelector(
    (state) => state?.getTopCollections
  );
  const { result, loading } = getTopCollectionsSelector;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTopCollectionsAPI(timeStamp));
  }, [timeStamp]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };
  const handleOnCloes = () => setOpenMenu(false);
  const handleMenuFilter = (event) => {
    event.preventDefault();
  
    setFilter(() => event.target.textContent);
  
    const filterType = event.target.textContent;
    let startDate, endDate;
  
    switch (filterType) {
      case "Last Month":
        startDate = moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
        endDate = moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");
        break;
      case "Week":
        startDate = moment().subtract(1, "weeks").startOf("week").format("YYYY-MM-DD");
        endDate = moment().subtract(1, "weeks").endOf("week").format("YYYY-MM-DD");
        break;
      case "Day":
        startDate = moment().startOf("day").format("YYYY-MM-DD");
        endDate = moment().endOf("day").format("YYYY-MM-DD");
        break;
      default:
        break;
    }
  
    setTimeStamp({ startDate, endDate });
  };
  

  console.log("==>timeStamp", timeStamp)

  return (
    <>
      <Box sx={{ mt: 10 }}>
        {/* <Typography variant="h4" sx={{ color: "#fff", my: 4 }} align="center">
          Top Collections
        </Typography> */}
        <Stack
          direction="row"
          alignItems="baseline"
          justifyContent="space-between"
          sx={{padding: "0px 10px"}}
        >
          <Stack
            direction={{xs: "col", md: "row"}}
            alignItems="center"
            justifyContent={"center"}
            spacing={1}
            width={"100%"}
          >
            <Typography
              variant="h4"
              sx={{ color: "#fff", mt: 8 }}
              align="center"
            >
              Top Collection over
            </Typography>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
            <Button
              sx={{ fontSize: 18, color: "primary.main" }}
              onClick={handleMenu}
            >
              {filter}
            </Button>
            <ExpandMoreIcon
              onClick={handleMenu}
              className="text-white "
            ></ExpandMoreIcon>
            </div>
          </Stack>
          <div>
            <Menu anchorEl={anchorEl} open={openMenu} onClose={handleOnCloes}>
              <MenuItem onClick={handleMenuFilter} data-val={"Last Month"}>
                Last Month
              </MenuItem>
              <MenuItem onClick={handleMenuFilter} data-val={"Week"}>
                Week
              </MenuItem>
              <MenuItem onClick={handleMenuFilter} data-val={"Day"}>
                Day
              </MenuItem>
            </Menu>
          </div>
        </Stack>
        <DividerUI />
        <Container>
          {loading ? (
            <LoaderUI />
          ) : (
            <Grid container spacing={3}>
              {result && result.length ?  
              result?.map((item) => {
                return (
                  <Grid
                    item
                    lg={3}
                    md={3}
                    sm={6}
                    xs={12}
                    onClick={() => navigate(`/collection/${item._id}`)}
                  >
                    <Card sx={{ bgcolor: "#050505", p: 0 }}>
                      <Box
                        sx={{ width: "100%", height: 100, overflow: "hidden" }}
                      >
                        <img
                          src={item.cover}
                          width={"100%"}
                          height={"100%"}
                          alt="coverImage"
                        />
                      </Box>
                      <Stack
                        sx={{ color: "#fff", mt: -5, p: 2 }}
                        alignItems="center"
                        spacing={2}
                      >
                        <Avatar
                          src={item.picture}
                          alt="picture"
                          sx={{ width: 70, height: 70 }}
                        />
                        <Typography variant="h6">{item?.name}</Typography>
                      </Stack>
                    </Card>
                  </Grid>
                );
              }):
              <>
                <Grid container sx={{justifyContent:"center", marginTop:"40px", flexDirection:"column", alignItems:"center"}}>
                  <Empty/><br/>
                <Typography>No Data</Typography>
                </Grid>
                </>
              }
            </Grid>
          )}
        </Container>
      </Box>
    </>
  );
};

export default TopCollectors;
