import { Box, Card, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { HiShoppingBag } from "react-icons/hi";
import WorkImg2 from "../../assets/png/workImg2.png";
import HomeVectorImg1 from "../../assets/svg/home2.svg";
import WorkImg1 from "../../assets/svg/workImg1.svg";
import ArrowImg1 from "../../assets/png/arrowImg1.png";
import ArrowImg2 from "../../assets/png/arrowImg2.png";
import Folder from "../../assets/svg/folder.svg";
import ShoppingBag from "../../assets/svg/shoppingBag.svg";
import Style from "../../styles/work.module.scss";

const Work = () => {
    return (
        <Box className={Style.main}>
            <Container >
                <Typography sx={{ my: 4 }} align="center" variant="h4">How do we work ?</Typography>
                <Grid container spacing={3} alignItems="center" sx={{ mb: 4 }}>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Box className={Style.mainGradCard1}>
                            <Card className={Style.gradCard1}>
                                <img src={WorkImg1} alt="workImage" />
                            </Card>
                            <img className={Style.backImg} src={WorkImg2} alt="workImage" />
                        </Box>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={12}>
                        <Card className={Style.gradCard2}>
                            <Typography className={Style.titleText1}>Connect Wallet</Typography>
                            <Typography className={Style.bodyText}>Securely link your assets to the blockchain with Connect Wallet</Typography>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container spacing={3} alignItems="center" sx={{ mb: 4 }}>
                    <Grid item lg={8} md={8} sm={8} xs={12}>
                        <Card className={Style.gradCard3}>
                            <Typography className={Style.titleText}>NFT Marketplace</Typography>
                            <Typography className={Style.bodyText}>Explore, buy, and sell one-of-a-kind digital assets on our NFT Marketplace.</Typography>
                        </Card>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Card className={Style.gradCard4}>
                            <img src={Folder} alt="folderImage" />
                        </Card>
                    </Grid>
                </Grid>

                <Grid container spacing={3} alignItems="center" sx={{ mb: 4 }}>
                    <Grid item lg={4} md={4} sm={4} xs={12} rowGap={1}>
                        <Card className={Style.gradCard5}>
                            <img src={ShoppingBag} alt="folderImage" />
                        </Card>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={12}>
                        <Card className={Style.gradCard6}>
                            <Typography className={Style.titleText2}>Collect & Sell NFTs</Typography>
                            <Typography className={Style.bodyText}>Build your unique collection and profit from it with our NFT Collect and Sell platform.</Typography>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
export default Work;

