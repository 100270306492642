import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpService from "../../config/authAxios";

const initialState = { loading: false, result: null, error: null, message: "" };

export const getBlog = createAsyncThunk(
  "getBlog",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const response = await HttpService.get(
        `/blogs/getBlogsByCategory/${data}`
      );
      return await response.data;
      // console.log("blogs calling response==>", response)
      // return "hello from blogs"
    } catch (error) {
      console.log("error blogs");
      return rejectWithValue(error.response.data);
    }
  }
);

const counterSlice = createSlice({
  name: "getBlog",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getBlog.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBlog.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload?.result;
        state.message = action.payload?.message;
      })
      .addCase(getBlog.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        state.message = action.payload?.message;
      });
  },
});

const blogsReducer = counterSlice.reducer;

export default blogsReducer;
