import { Card, Modal } from "@mui/material";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { getClbHistoryGraphAPI } from "../../redux/clb/getClbHistoryGraph";
import { useParams } from "react-router-dom";
import { dateYYMMDD } from "../../helper/date";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      title: {
        display: true,
        text: "Month",
      },
    },
    y: {
      grid: {
        display: false,
      },
      title: {
        display: true,
        text: "Prices (INR / USD)",
      },
    },
  },
};

export function CLBGraph({ open, setOpen, clb }) {
  const getClbHistoryGraphSelector = useSelector(
    (state) => state?.getClbHistoryGraph
  );
  const { result, loading } = getClbHistoryGraphSelector;
  const dispatch = useDispatch();
  const { id } = useParams();

  const [currency, setCurrency] = useState("inr");
  const [period, setPeriod] = useState("last 7 days");

  const labels = result?.length
    ? [...result?.map((item) => item?.label)]
    : [];
  const price = result?.length
    ? [...result?.map((item) => item?.price)]
    : [];


  const data = {
    labels: labels,
    datasets: [
    
      {
        label: "CLBs Price",
        data: price,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    console.log("called");
    dispatch(getClbHistoryGraphAPI({id,currency,period}));
  }, [currency,period]);

  console.log(currency, period);

  return (
    <Modal open={open} onClose={handleClose} className="modal">
      <div className="w-fit p-4 flex flex-col gap-4 bg-white ">
        <div className="w-full gap-2 flex items-center justify-end">
          <select
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
            className="border border-primary text-primary rounded-md py-1 px-2"
          >
            <option value="inr">INR</option>
            <option value="usd">USD</option>
          </select>
          <select
            value={period}
            onChange={(e) => setPeriod(e.target.value)}
            className="border border-primary text-primary rounded-md  py-1 px-2"
          >
            <option value="last 7 days">7 Days</option>
            <option value="this month">This Month</option>
            <option value="this year">This Year</option>
            <option value="all time">All Time</option>
          </select>
        </div>
        <Card sx={{ bgcolor: "#fff" }}>
          <Line options={options} data={data} width={700} height={500} />
        </Card>
      </div>
    </Modal>
  );
}
