import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Style from "./Aboutus.module.css";
import GetTouch from "./Getintouch";
import Navbar from "./Navbar";
import Services from "./WhyUs";
import OurWork from "./whatwedo";
import Whoarewe from "./whoarewe";
import Header from "../../header/Header";
import { Box } from "@mui/material";
import Axios from "../../../config/authAxios";
// import { GetInTouch } from "../../../helper/component";

const AboutUs = () => {
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const [resource, setResource] = useState();
  async function getResources() {
    const response = await Axios.get(`/resource/get-resource-details`);
    setResource(response.data.result);
    // console.log("🚀 ~ getResources ~ response:==>2", response)
    console.log("==>", resource);
  }
  useEffect(() => {
    getResources();
  }, []);

  useEffect(()=>{
    window.scroll(0,0)
  },[])

  return (
    <>
      <Box className={Style.main}>
        <Header />
        <div className={Style.container}>
          <div>
            <Navbar />
            {/* <Whoarewe/> */}
            {path == "/aboutUs" && <Whoarewe resource={resource} />}
            {path == "/aboutUs/ourWork" && <OurWork resource={resource} />}
            {path == "/aboutUs/services" && <Services resource={resource} />}
            {/* {path == "/aboutUs/whyUs" && <WhyUs resource={resource} />} */}
            {path == "/aboutUs/getInTouch" && (
              <GetTouch resource={resource} />
            )}
          </div>
        </div>
      </Box>
    </>
  );
};
export default AboutUs;
