import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function NoCart(handleClose) {
  const navigate = useNavigate();
  let [route, setRouteValue] = useState(window.navigation.currentEntry.url);
  const handleNavigate = () => {
    // console.log("handleClose", handleClose);

    if (route.includes("explore")) {
      handleClose.clostFunction();
    } else {
      navigate("/explore");
    }
  };

  return (
    <>
      <Stack alignItems="center" spacing={2}>
        <Typography variant="h4">Uh-ho!</Typography>
        <Typography>Your Cart is empty</Typography>
        <ShoppingCartIcon sx={{ fontSize: 100 }} />
        {/* <Button variant="contained" onClick={() => navigate("/explore")}> */}

        {/* {route.contains("explore") ?<div>abc</div>: <div>asdf</div>} */}
        <Button variant="contained" onClick={handleNavigate}>
          {/* <Button variant="contained" onClick={handleClose}> */}
          {/* {route} */}
          {/* {route.includes("explore") ? "Go to Home" : "Go to Marketplace"} */}
          Go to Marketplace
        </Button>
      </Stack>
    </>
  );
}
