import styled from '@emotion/styled';
import { Autocomplete, OutlinedInput, TextField } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#35ABAE',
      error: '#FFFF49',
      warn: '#FFFF49'
    },
  },


  components: {
    // Typography ------------>
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#fff',
          textTransform: 'none',
          wordBreak: 'break-word'
        }
      },
    },
    // Button ------------>
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#fff',
          borderRadius: 10,
          textTransform: 'none'
        }
      }
    },
    // TextFiled -------->
    MuiTextField: {
      styleOverrides: {
        root: {
          background: "#484848",
        }
      },
      defaultProps: {
        InputLabelProps: {
          shrink: true
        }
      }

    },
    // MuiOutlinedInput -------->
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: '#fff'
        }
      }
    },
    // MuiInputLabel -------->
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#fff'
        }
      }
    },
    // Button ----------->
    MuiIconButton: {
      styleOverrides: {
        sizeMedium: {
          color: '#fff'
        }
      }
    },
    // Menu --------->
    MuiMenu: {
      styleOverrides: {
        root: {
          overflow: 'scroll',
        },
        list: {
          padding: 10,
          backgroundColor: '#050505',
          color: '#fff',
          border: '1px solid #484848',
          borderRadius: 4
        },
      },
    },
    // Card ---------->
    MuiCard: {
      styleOverrides: {
        root: {
          background: '#232323',
          border: '1px solid #818181',
          borderRadius: 20,
          color: "#fff",
          padding: 20
        }
      },
    },
    // accordion------>
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: '#000'
        },
      }
    },
    // checkbox ---------->
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#fff'
        }
      }
    },
    // Radio----------->
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#35ABAE'
        }
      }
    },
    // Autocomplete
    MuiAutocomplete: {
      defaultProps: {
        InputLabelProps: {
          shrink: true
        }
      }
    },
    // Radio
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#484848'
        }
      }
    },
    //Container
    MuiContainer: {
      defaultProps: {
        disableGutters: true,
        maxWidth: false,
      },
      styleOverrides: {
        root: {
          padding: '0 30px'
        }
      }
    },

    //Dailog
    MuiDialog: {
      defaultProps: {
        disableGutters: true,
        maxWidth: false,
      },
    },
  }
});


export const TextFieldUI = styled(TextField)({
  background: '#fff',
});

export const OutlinedInputUI = styled(OutlinedInput)({
  background: '#fff',
});


export const AutocompleteUI = styled(Autocomplete)({
  "& .MuiAutocomplete-inputRoot": {
    color: "#000",
  }
});



export default muiTheme