import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {
  Box,
  Button,
  Container,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactSlider from "../../common/ReactSlider";
import { getLiveCLBsAPI } from "../../redux/clb/getLiveCLBs";
import { changeCLBsFavStatusAPI } from "../../redux/favorite/changeCLBsFavStatus";
import Style from "../../styles/liveClb.module.scss";
import DividerUI from "../../ui/DividerUI";
import LoaderUI from "../../ui/LoaderUI";
import Snack from "../../ui/Snack";
import UserNotLoggedIn from "../../common/UserNotLoggedIn";
import { CLBEndDateTimer, normalDate } from "../../helper/timer";
import { CountdownTimer } from "../../helper/component";
// import moment from "moment";

const date = new Date();
date.setDate(date.getDate() + 23);

const LiveCLBSection = () => {
  const getLiveCLBsSelector = useSelector((state) => state?.getLiveCLBs);
  const likedSelector = useSelector((state) => state?.changeCLBsFavStatus);
  const { result, loading } = getLiveCLBsSelector;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getCurrentUserSelector = useSelector((state) => state?.getCurrentUser);
  const { result: userResult, error: userError } = getCurrentUserSelector;

  const changeCLBsFavStatusSelector = useSelector(
    (state) => state?.changeCLBsFavStatus
  );
  const { message, error } = changeCLBsFavStatusSelector;

  const [userNotLoggedIn, setUserNotLoggedIn] = useState(false);

  const handleFavirote = async (fav, clbId) => {
    if (userError) {
      return setUserNotLoggedIn(true);
    }
    dispatch(changeCLBsFavStatusAPI({ isFavorite: fav, clbId }));
  };

  useEffect(() => {
    dispatch(getLiveCLBsAPI({ ownerId: userResult?._id }));
  }, [userResult]);

  useEffect(() => {
    dispatch(getLiveCLBsAPI({ ownerId: userResult?._id }));
  }, [likedSelector]);

  return (
    <>
      <Box sx={{ mt: 10 }}>
        <Typography align="center" variant="h4">
          Live CLBs
        </Typography>
        <DividerUI />

        <Container>
          {loading ? (
            <LoaderUI />
          ) : (
            <ReactSlider autoplay={false}>
              {result?.map((item, idx) => {
                return (
                  <>
                    <Paper className="SingelCLBBox" sx={{ m: 1 }}>
                      <Stack
                        alignItems="center"
                        justifyContent="space-between"
                        direction="row"
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          sx={{ px: 2, zIndex: 100 }}
                          pt={2}
                          pb={4}
                        >
                          <CountdownTimer endDate={item.endDate} />
                        </Stack>
                        {item?.favorites || item?.favorites != undefined ? (
                          <IconButton
                            onClick={() => handleFavirote(false, item?._id)}
                            className="likeBtn"
                          >
                            <FavoriteIcon sx={{ color: "primary.main" }} />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => handleFavirote(true, item?._id)}
                            className="likeBtn"
                          >
                            <FavoriteBorderIcon
                              sx={{ color: "primary.main" }}
                            />
                          </IconButton>
                        )}
                      </Stack>
                      <Box className="CLBBox">
                        {item?.type == "2d" && (
                          <img src={item?.files?.[0]} width={"100%"} />
                        )}
                        {item?.type == "3d" && (
                          <model-viewer
                            style={{ width: "100%", height: 250 }}
                            src={item?.files?.[0]}
                            ar-modes="webxr scene-viewer quick-look"
                            camera-controls
                            poster="poster.webp"
                            shadow-intensity="1"
                            disable
                          ></model-viewer>
                        )}
                      </Box>
                      <Stack sx={{ p: 2 }}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ mt: 2 }}
                          alignItems="center"
                        >
                          <Typography variant="p">{item?.title}</Typography>
                          <Typography variant="h6" className={Style?.price}>
                            ₹ {item?.cost?.inr}
                          </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                          <Button className="gradBtn" variant="contained">
                            <Box>
                              <Typography
                                variant="subtitle2"
                                sx={{ color: "#999999" }}
                              >
                                Higest Bid
                              </Typography>
                              <Typography variant="p">
                                ₹ {item?.price ?? 0}
                              </Typography>
                            </Box>
                          </Button>
                          {item?.isBidAble ? (
                            <Button
                              className={`${Style?.startBidBtn} gradBtn`}
                              variant="contained"
                              onClick={() => navigate(`/explore/${item._id}`)}
                              style={{
                                color:
                                  new Date(item.endDate).getTime() <
                                  new Date().getTime()
                                    ? "#f50057"
                                    : "inherit",
                              }}
                              disabled={
                                new Date(item.endDate).getTime() <
                                new Date().getTime()
                                  ? true
                                  : false
                              }
                            >
                              {new Date(item.endDate).getTime() <
                              new Date().getTime()
                                ? "Ended"
                                : "Start Bid"}
                              {/* Start Bid */}
                            </Button>
                          ) : (
                            <Button
                              className={`${Style?.startPurchaseBtn} gradBtn`}
                              variant="contained"
                              onClick={() => navigate(`/explore/${item._id}`)}
                            >
                              Purchase
                            </Button>
                          )}
                        </Stack>
                      </Stack>
                    </Paper>
                  </>
                );
              })}
            </ReactSlider>
          )}
        </Container>
      </Box>
      {userNotLoggedIn ? (
        <UserNotLoggedIn open={userNotLoggedIn} setOpen={setUserNotLoggedIn} />
      ) : null}
    </>
  );
};

export default LiveCLBSection;
