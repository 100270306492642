import { Avatar, Button, Chip, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import Style from "../../styles/topBid.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoaderUI from "../../ui/LoaderUI";
import { allNotificationAPI } from "../../redux/notification/allNotification";
import { useEffect } from "react";
import UserNotLoggedIn from "../../common/UserNotLoggedIn";
import { allUnseenNotificationCountAPI } from "../../redux/notification/allUnseenNotificationCount";
import AllNotification from "../../components/notification/AllNotification";
import UnSeenNotification from "../../components/notification/UnSeenNotification";

export default function Activities() {
  const [FilterBtn, setFilterBtn] = useState("All");
  const [userNotLoggedIn, setUserNotLoggedIn] = useState(false);

  const handleFilterNotificationBtn = async (value) => {
    setFilterBtn(value);
  };

  return (
    <>
      {/* <Stack direction="row" spacing={2} sx={{ p: 2 }}>
        <Button
          onClick={() => handleFilterNotificationBtn("All")}
          className={FilterBtn == "All" ? "roundBtn" : "roundBtnInActive"}
          variant="contained"
        >
          All
        </Button>
        <Button
          onClick={() => handleFilterNotificationBtn("UnSeen")}
          className={FilterBtn == "Unseen" ? "roundBtn" : "roundBtnInActive"}
          variant="contained"
        >
          UnSeen
        </Button>
      </Stack> */}
      <AllNotification />
    </>
  );
}
