import moment from "moment";

export const CLBEndDateTimer = (date) => {
    let now = moment(new Date())
    let duration = moment.duration(now.diff(date));
    const result = duration?._data
    return `${result?.days}:${result?.hours}:${result?.minutes}:${result?.seconds}`
}

export const normalDate = (date) => {
    return moment(date).format("DD-MM-YYYY HH:MM:SS")
}


setInterval(() => { CLBEndDateTimer() }, 1000);