import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Card, Grid, IconButton, Modal, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';

export default function UserNotLoggedIn({ open, setOpen }) {
    const navigate = useNavigate()

    const handleClose = () => setOpen(false)

    return (
        <Modal open={open} onClose={handleClose} className='modal'>
            <Grid container justifyContent='center' sx={{ p: 2 }}>
                <Grid lg={5} md={7} sm={9} xs={12} >
                    <Card className='alertBox'>
                        <Stack spacing={2}>
                            <Stack direction='row' justifyContent='center' alignItems='center' sx={{ my: 2 }} >
                                <Typography align='center' variant='h5'>Oh no!</Typography>
                                <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 10 }}><CloseIcon /></IconButton>
                            </Stack>
                            <Stack spacing={2} alignItems='center'>
                                <ErrorIcon color='error' sx={{ width: 100, height: 100 }} />
                                <Typography>You are not logged in yet. Please Sign In / Sign Up first!</Typography>
                                <Button onClick={() => navigate("/signIn")} fullWidth variant='contained' endIcon={<ArrowRightAltIcon />}>Sign-In</Button>
                                <Button onClick={() => navigate("/signUp")} fullWidth variant='contained' endIcon={<ArrowRightAltIcon />}>Sign-Up</Button>
                                <Button onClick={() => navigate("/orgSignUp")} fullWidth variant='contained' endIcon={<ArrowRightAltIcon />}>Sign-Up with Organisation</Button>
                            </Stack>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </Modal >
    )
}
