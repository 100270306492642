import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  AppleAuthProvider,
} from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import HttpService from "../config/authAxios";
import toast from "react-hot-toast";

// const firebaseConfig = {
//     apiKey: "AIzaSyBWmilqitbSEhkoLz0Ko8awHi_PG3hTsjw",
//     authDomain: "eduthum-25a70.firebaseapp.com",
//     projectId: "eduthum-25a70",
//     storageBucket: "eduthum-25a70.appspot.com",
//     messagingSenderId: "222086770122",
//     appId: "1:222086770122:web:b8e7df96f778f4cefbf333",
//     measurementId: "G-46QJ9PFKRE"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyAKdC68DL1EsStzZBdMLaZu3OPTWv9zSac",
//   authDomain: "eduthum-533df.firebaseapp.com",
//   projectId: "eduthum-533df",
//   storageBucket: "eduthum-533df.appspot.com",
//   messagingSenderId: "621435314033",
//   appId: "1:621435314033:web:ee3aee2409d1b58c2620d0",
//   measurementId: "G-BSCELYCLG4",
// };

// Firebase configuration - Harsh's account
const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

const gmailProvider = new GoogleAuthProvider();
const appleProvider = new GoogleAuthProvider();
const facbookProvider = new FacebookAuthProvider();

export const firebase = initializeApp(firebaseConfig);
const messaging = getMessaging(firebase);

export const auth = getAuth();

export const signInWithGooglePopup = () => signInWithPopup(auth, gmailProvider);
export const signInWithApplePopup = () => signInWithPopup(auth, appleProvider);
export const signInWithFacebookPopup = () =>
  signInWithPopup(auth, facbookProvider);

export const getFCMToken = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey: process.env.REACT_APP_FCM_VAPID_KEY,
  })
    .then((currentToken) => {
      console.log(">>", currentToken);
      if (currentToken) {
        setTokenFound(true);
        const userId = localStorage.getItem("userId");
        HttpService.post(
          "/users/token-details",
          {},
          {
            headers: {
              "x-user-id": userId,
              "x-fcm-token": currentToken,
            },
          }
        )
          .then((res) => console.log("res", res))
          .catch((err) => console.log("err", err));
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
