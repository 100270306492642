import React, { useState } from "react";
import styles from "./Aboutus.module.css";
import { GetInTouch } from "../../../helper/component";
import Axios from "../../../config/authAxios";
import { capitalizeFirstLetter } from "../../../helper/capitalise";

const GetIntouch = () => {
  const [data, setData] = useState({ name: "", email: "", query: "" });
  const [errors, setErrors] = useState({ name: "", email: "", query: "" });
  const handleChange = (e) => {
    let { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate(data)) {
      return;
    }
    await Axios.post("/ticket/getInTouch", data)
      .then((res) => {
        console.log("get in touch response==>", res.data);
        setData({ name: "", email: "", query: "" });
      })
      .catch((error) => console.log(error));
  };

  const validate = (data) => {
    let flg = true;
    const obj = {};
    Object.keys(data).map((key) => {
      if (data[key] === "" || !data[key]) {
        flg = false;
        obj[key] = `${capitalizeFirstLetter(key)} cannot be empty`;
        setErrors(obj);
      }
    });
    return flg;
  };

  return (
    <div className={styles.getInTouch}>
      <GetInTouch
        data={data}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        errors={errors}
      />
    </div>
  );
};
export default GetIntouch;
