import { Avatar, Box, Container, Grid, Stack, Typography } from '@mui/material';
import { default as React, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userFollowingAPI } from '../../redux/user/userFollowing';



const Following = (id) => {
    const userFollowingSelector = useSelector(state => state?.userFollowing)
    const { result, loading } = userFollowingSelector
    const dispatch = useDispatch()
    const navigate = useNavigate();


    useEffect(() => {
        dispatch(userFollowingAPI(id))
    }, []);

    return (
        <>
            <Box sx={{ mt: 10 }} >
                <Typography variant='h5' align='center'>People who followed by you</Typography>
                <Container sx={{ my: 4 }}>
                    <Grid container spacing={3}>
                     {console.log("Followwwwwwwwwwwing",userFollowingSelector)}
                        {result?.map((item, idx) => {
                            return (
                                <Grid item xs={12} sm={6} md={3} lg={3} onClick={() => navigate(`/profile/${item._id}`)}>
                                    <Box className='gradBox'>
                                        <Stack direction='row' spacing={2}>
                                            <Avatar src={item?.picture} variant="rounded" sx={{ width: 100, height: 100 }} />
                                            <Box>
                                                <Typography color='primary'>{item?.name?.toUpperCase()}</Typography>
                                                <Typography>{item?.email}</Typography>
                                            </Box>
                                        </Stack>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </Box>
        </>

    );
};

export default Following;
