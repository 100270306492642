import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import HttpService from "../../config/authAxios"

const initialState = { loading: false, result: null, error: null, message: "" }

export const forgetPasswordAPI = createAsyncThunk(
    'forget-password',
    async (data, { rejectWithValue }) => {
        try {
            const response = await HttpService.post(`/users/forget-password`, data)
            return await response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }

    }
)

const counterSlice = createSlice({
    name: 'forget-password',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(forgetPasswordAPI.pending, (state, action) => {
                state.loading = true
            })
            .addCase(forgetPasswordAPI.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload?.result
                state.message = action.payload?.message
            })
            .addCase(forgetPasswordAPI.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.error
                state.message = action.payload?.message
            })
    },
})

const forgetPasswordReducer = counterSlice.reducer

export default forgetPasswordReducer

