import httpService from "../config/authAxios"

export async function getAllCart() {
    const cart = await httpService.get("/cart/clbs")
    return cart
}
export async function addToCart(id) {
    const carts = await httpService.post(`/cart/clbs/${id}`)
    return carts
}

export const clearCart = () => httpService.delete(`/cart/clbs/clear/all`)
export const removeSingleClbOfCart = (clbId) => httpService.delete(`/cart/clbs/clear/${clbId}`)
