import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Card, IconButton, Modal, Stack, Typography,Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HttpService from "../../src/config/authAxios";
import toast from "react-hot-toast";

const IndianConfirmModal = ({ open, setOpen,setBuyCLBComf }) => {
  const handleClose = () => setOpen(false);
  
  return (
    <Modal open={open} onClose={handleClose} className="modal">
      <Card className="alertBox">
        <Stack direction="row" sx={{ my: 2 }}>
          <Typography align="center" variant="h5" sx={{ flex: 1 }}>
            Buy Course !
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack spacing={2}>
          <Stack spacing={2}>
            <Typography>
              {" "}
              Are you sure you want to buy the Course. We will gift you this Clb
              for free.
            </Typography>
          </Stack>
          <div className="w-full flex items-center gap-4 justify-end">
            <Button
              className="gradBtn2"
              onClick={() => {
             
                setBuyCLBComf(true);
                handleClose();
              }}
            >
              Buy
            </Button>
            <button className="btn btn-primary" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </Stack>
      </Card>
    </Modal>
  );
};

export default IndianConfirmModal;
