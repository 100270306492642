import * as React from "react";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/header/Header";
import { getAllGalleryAPI } from "../../redux/gallery/getAllGallery";
import Style from "../../styles/gallery.module.scss";
import LoaderUI from "../../ui/LoaderUI";
import { PhotoProvider } from "react-photo-view";
import ImageModal from "./ImageModal";

const Galleries = () => {
  const getAllGallerySelector = useSelector((state) => state?.getAllGallery);
  const { result, loading } = getAllGallerySelector;

  const dispatch = useDispatch();

  const [openModals, setOpenModals] = React.useState(
    Array(result?.length)?.fill(false)
  );

  const handleOpen = (idx) => {
    console.log("idx=>", idx)
    const newOpenModals = [...openModals];
    console.log("🚀 ~ handleOpen ~ newOpenModals:1", newOpenModals)
    newOpenModals[idx] = true;
    console.log("🚀 ~ handleOpen ~ newOpenModals:2", newOpenModals)
    setOpenModals(newOpenModals);
  };

  const handleClose = (idx) => {
    const newOpenModals = [...openModals];
    newOpenModals[idx] = false;
    setOpenModals(newOpenModals);
  };

  React.useEffect(() => {
    dispatch(getAllGalleryAPI());
  }, []);

  return (
    <PhotoProvider>
      <Box className={Style.main}>
        <Header />
        <Container>
          <Stack sx={{ mt: 3 }} spacing={4}>
            <Typography variant="h4" align="center">
              Gallery
            </Typography>
            <Grid container rowGap={4} columnGap={2} justifyContent="center">
              {loading ? (
                <LoaderUI />
              ) : (
                result?.map((ele, idx) => {
                  return (
                    <Grid
                      style={{ cursor: "pointer" }}
                      item
                      lg={3}
                      md={3}
                      sm={6}
                      xs={12}
                      key={idx}
                    >
                      <img
                        src={ele?.image}
                        onClick={() => handleOpen(idx)}
                        height={300}
                        width={"100%"}
                        alt="galleryImages"
                      />
                      {console.log("real Idx", idx )}
                      <ImageModal
                        handleClose={() => handleClose(idx)}
                        open={openModals[idx]}
                        ele={idx}
                        imageArr={result}
                      />
                      <Box pt={2}>
                        <Typography
                          fontSize={14}
                          sx={{ pt: 1 }}
                          variant="p"
                          align="center"
                        >
                          {ele?.description}
                        </Typography>
                      </Box>
                    </Grid>
                  );
                })
              )}
            </Grid>
          </Stack>
        </Container>
      </Box>
    </PhotoProvider>
  );
};

export default Galleries;
