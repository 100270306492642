import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SortIcon from "@mui/icons-material/Sort";
import {
  Slider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Menu,
  MenuItem,
  Radio,
  Stack,
  Typography,
  RadioGroup,
  FormControl,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/header/Header";
import { getAllCategoryAPI } from "../../redux/category/getAllCategory";
import { getExploreCLBsAPI } from "../../redux/explore/getExploreCLBs";
import ExploreCLBs from "./ExploreCLBs";
import Style from "../../styles/explore.module.scss";
import { getPriceSliderAPI } from "../../redux/explore/getPriceSlider";

const Explore = () => {
  const [offers, setOffers] = useState([
    { title: "On Sale" },
    { title: "On Discount" },
  ]);
  const getAllCategorySelector = useSelector((state) => state?.getAllCategory);
  const { result: categories } = getAllCategorySelector;
  // console.log("🚀 ~ Explore ~ categories:", categories)
  // const getAllOffersSelector = useSelector((state) => state?.getAllOffers);
  // const { result: offers } = getAllOffersSelector;

  const getExploreCLBsSelector = useSelector((state) => state?.getExploreCLBs);
  const { result, loading } = getExploreCLBsSelector;

  const getPriceSliderSelector = useSelector((state) => state?.getPriceSlider);
  const { result: priceSliderResult } = getPriceSliderSelector;

  const getCurrentUserSelector = useSelector((state) => state?.getCurrentUser);
  const { result: userResult } = getCurrentUserSelector;

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const openFilter = Boolean(anchorEl);
  const [anchorElSort, setAnchorElSort] = useState(null);
  const openFilterSort = Boolean(anchorElSort);
  const [activeFilterCategoty, setActiveFilterCategoty] = useState("All");
  const [activeBid, setActiveBid] = useState(null);
  const [sortFilter, setSortFilter] = useState("");
  const [page, setPage] = useState(1);
  let isBidAble = null;

  const [filters, setFilters] = useState({
    topBid: false,
    topCollectors: false,
    priceRange: [priceSliderResult?.min, priceSliderResult?.max],
    category: "",
    offers: {
      onSale: false,
      noDiscount: false,
    },
    others: {
      onSale: false,
      noDiscount: false,
    },
  });

  const handleFilterToggle = (event) => setAnchorEl(event.currentTarget);
  const handleCloseFilter = () => setAnchorEl(null);
  const handleFilterToggleSort = (event) =>
    setAnchorElSort(event.currentTarget);
  const handleCloseFilterSort = () => setAnchorElSort(null);

  const handleExploreCLBsFilters = (event, idx) => {
    let filter = {
      ...filters,
      topBid: false,
      topCollectors: false,
      priceRange: [0, 1000000],
      others: {
        onSale: false,
        noDiscount: false,
      },
      page: Number(page),
    };

    const name = event?.target?.name;
    const value = event?.target?.value;
    const checked = event?.target?.checked;
    const Id = event?.target?.id;

    setActiveFilterCategoty(event?.target?.value);
    name === "bid" && setActiveBid(event?.target?.value);

    if (Id == "topBid") filter = { ...filter, topBid: true };
    if (name == "priceRange") filter = { ...filter, priceRange: value };
    if (name == "category") {
      if (checked) filter = { ...filter, category: value?._id };
      else filter = { ...filter, category: "" };
    }
    if (value == "onSale")
      filter = {
        ...filter,
        others: { ...filter?.others, onSale: checked },
      };
    if (value == "bid") filter = { ...filter, isBidAble: true };
    if (value == "purchase") filter = { ...filter, isBidAble: false };

    setFilters(filter);
    console.log("activeFilterCategoty", activeFilterCategoty);
    dispatch(
      getExploreCLBsAPI({
        ...filter,
        ownerId: userResult?._id,
        sortFilters: sortFilter,
      })
    );
  };

  const handleCatFilter = async (type, catId) => {
    setFilters({ ...filters, category: catId });
    setActiveFilterCategoty(type);
    await dispatch(
      getExploreCLBsAPI({
        ...filters,
        ownerId: userResult?._id,
        category: catId,
        sortFilter,
      })
    );
  };

  const handleSortFilters = async (type) => {
    await dispatch(
      getExploreCLBsAPI({
        ownerId: userResult?._id,
        ...filters,
        sortFilters: type,
      })
    );
    setSortFilter(type);
  };

  useEffect(() => {
    if (userResult) {
      dispatch(
        getExploreCLBsAPI({
          ...filters,
          ownerId: userResult?._id,
          page: page,
          sortFilters: sortFilter,
        })
      );
    } else
      dispatch(
        getExploreCLBsAPI({ ...filters, page: page, sortFilters: sortFilter })
      );
  }, [userResult, page]);

  useEffect(() => {
    dispatch(getPriceSliderAPI());
    dispatch(getAllCategoryAPI());
  }, []);

  useEffect(() => {
    handleExploreCLBsFilters({
      target: {
        name: "priceRange",
        value: [priceSliderResult.min, priceSliderResult.max],
      },
    });
  }, [priceSliderResult]);

  return (
    <>
      <Box className={result?.length <= 4 ? Style.loadinMain : Style.main}>
        <Header />
        <Container>
          <Grid container sx={{ mt: 10 }}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Button
                sx={{ m: 1 }}
                className={
                  activeFilterCategoty == "All"
                    ? Style?.activeFilter
                    : Style?.inactiveFilter
                }
                onClick={() => handleCatFilter("All")}
              >
                All
              </Button>
              {categories?.map((item, idx) => {
                return (
                  <Button
                  style={{
                    maxWidth: "200px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    display: "inline-block",
                  }}
                    sx={{ m: 1 }}
                    className={
                      filters.category == item?._id
                        ? Style?.activeFilter
                        : Style?.inactiveFilter
                    }
                    onClick={() => handleCatFilter(item?.title, item?._id)}
                  >
                    {item?.title}
                  </Button>
                );
              })}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} textAlign="end">
              <Button
                onClick={handleFilterToggle}
                className="whiteBtn"
                endIcon={<FilterAltIcon />}
              >
                Filter
              </Button>
              <Button
                onClick={handleFilterToggleSort}
                className="whiteBtn"
                endIcon={<SortIcon />}
              >
                Sort
              </Button>
            </Grid>
          </Grid>
          <ExploreCLBs
            sortFilters={sortFilter}
            filters={filters}
            activeBid={activeBid}
            page={page}
            setPage={setPage}
          />
        </Container>
      </Box>

      {/* filter menu */}
      <Menu
        anchorEl={anchorEl}
        open={openFilter}
        onClose={handleCloseFilter}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem id="topBid" onClick={handleExploreCLBsFilters}>
          Top Bids
        </MenuItem>
        <MenuItem>
          <Stack sx={{ width: "100%" }}>
            <Typography variant="p">Price Range</Typography>
            <Slider
              name="priceRange"
              value={filters?.priceRange}
              min={priceSliderResult.min}
              max={priceSliderResult.max}
              onChange={handleExploreCLBsFilters}
              fullWidth
              getAriaLabel={() => "Price Range"}
              valueLabelDisplay="auto"
            />
          </Stack>
        </MenuItem>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
          >
            <Typography>Category</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
              >
                {categories?.map((ele, idx) => {
                  return (
                    <FormControlLabel
                      checked={filters.category === ele?._id}
                      value={ele?.title}
                      control={<Radio />}
                      label={ele?.title}
                      onChange={(event) =>
                        handleCatFilter(ele?.title, ele?._id)
                      }
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        {/* <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
          >
            <Typography>Offers</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
              >
                {offers?.map((ele, idx) => {
                  return (
                    <FormControlLabel
                      checked={filters.offers === ele?._id}
                      value={ele?.title}
                      control={<Radio />}
                      label={ele?.title}
                      onChange={(event) =>
                        handleCatFilter(ele?.title, ele?._id)
                      }
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion> */}

        <MenuItem>
          <Stack spacing={1}>
            <Typography>Type</Typography>
            <RadioGroup onChange={handleExploreCLBsFilters}>
              <FormControlLabel
                name="bid"
                checked={activeBid === "bid"}
                value="bid"
                control={<Radio />}
                label="Bid"
              />
              <FormControlLabel
                name="bid"
                checked={activeBid === "purchase"}
                value="purchase"
                control={<Radio />}
                label="Purchase"
              />
            </RadioGroup>
          </Stack>
        </MenuItem>
      </Menu>

      {/* sortMenu */}
      <Menu
        anchorEl={anchorElSort}
        open={openFilterSort}
        onClose={handleCloseFilterSort}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => handleSortFilters("new")}
          className={sortFilter == "new" && Style?.activeFilterSort}
        >
          What’s new
        </MenuItem>
        <MenuItem
          onClick={() => handleSortFilters("alpha")}
          className={sortFilter == "alpha" && Style?.activeFilterSort}
        >
          Alphabetically
        </MenuItem>
        {/* <MenuItem
          onClick={() => handleSortFilters("discounts")}
          className={sortFilter ==="discounts" && Style?.activeFilterSort}
        >
          Discounts
        </MenuItem> */}
        <MenuItem
          onClick={() => handleSortFilters("lowprice")}
          className={sortFilter == "lowprice" && Style?.activeFilterSort}
        >
          Price: Low to high
        </MenuItem>
        <MenuItem
          onClick={() => handleSortFilters("hightprice")}
          className={sortFilter == "hightprice" && Style?.activeFilterSort}
        >
          Price: High to low
        </MenuItem>
      </Menu>
    </>
  );
};
export default Explore;
