import {
  Avatar,
  Box,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllHotCollectionsAPI } from "../../redux/collection/getAllHotCollections";
import DividerUI from "../../ui/DividerUI";
import LoaderUI from "../../ui/LoaderUI";
import PastelCardComponent from "../../components/cards/pastelCard";

const HotCollection = () => {
  const getAllHotCollectionsSelector = useSelector(
    (state) => state?.getAllHotCollections
  );
  const { result, loading } = getAllHotCollectionsSelector;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCollectionClick = (e, id) => {
    e.preventDefault();
    navigate(`/collection/${id}`);
  };

  useEffect(() => {
    dispatch(getAllHotCollectionsAPI());
  }, []);

  return (
    <>
      <Box sx={{ mt: 10 }}>
        <Typography variant="h4" sx={{ color: "#fff", mt: 8 }} align="center">
          Latest Collections
        </Typography>
        <DividerUI />
        <Container>
          {loading ? (
            <LoaderUI />
          ) : (
            <Grid container spacing={3}>
              {result?.map((item) => {
                // {stub?.map((item) => {
                return (
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}
                    onClick={() => navigate(`/collection/${item._id}`)}
                  >
                    <Card sx={{ bgcolor: "#050505", p: 0, cursor: "pointer" }}>
                      <PastelCardComponent item={item} />
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Container>
      </Box>
    </>
  );
};
export default HotCollection;
