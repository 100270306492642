import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpService from "../../config/authAxios";

const initialState = { loading: false, result: null, error: null, message: "" };

export const changeCLBsFavStatusAPI = createAsyncThunk(
  "change-clb-status-fav",
  async (data, { rejectWithValue }) => {
    try {
      const response = await HttpService.post(`/favorites/status`, data);
      return await response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const counterSlice = createSlice({
  name: "change-clb-status-fav",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(changeCLBsFavStatusAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(changeCLBsFavStatusAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload?.result;
        state.message = action.payload?.message;
      })
      .addCase(changeCLBsFavStatusAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        state.message = action.payload?.message;
      });
  },
});

const changeCLBsFavStatusReducer = counterSlice.reducer;

export default changeCLBsFavStatusReducer;
