import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Aboutus.module.css";
const Navbar = () => {
  const navigate = useNavigate();
  const path = useLocation().pathname;
  return (
    <div style={{ width: "100%" }}>
      <div className={styles.heading}>
        <h1
          onClick={() => navigate("/aboutUs")}
          className={path == "/aboutUs" ? styles.active : ""} // who are we?
        >
          Who are we?
        </h1>
        <h1
          onClick={() => navigate("/aboutUs/ourWork")}
          className={path == "/aboutUs/ourWork" ? styles.active : ""}
        >
          Our Work
        </h1>
        <h1
          onClick={() => navigate("/aboutUs/services")}
          className={path == "/aboutUs/services" ? styles.active : ""}
        >
          Services
        </h1>

        <h1
          onClick={() => navigate("/aboutUs/getInTouch")}
          className={path == "/aboutUs/getInTouch" ? styles.active : ""}
        >
          Get in touch?
        </h1>
      </div>
    </div>
  );
};
export default Navbar;
