import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import HttpService from "../../config/authAxios"

const initialState = { loading: false, result: null, error: null, message: "" }

export const allNotificationAPI = createAsyncThunk(
    'all-notification',
    async (data, { rejectWithValue }) => {
        try {
            const response = await HttpService.get(`/notification/all`)
            return await response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }

    }
)

const counterSlice = createSlice({
    name: 'all-notification',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(allNotificationAPI.pending, (state, action) => {
                state.loading = true
            })
            .addCase(allNotificationAPI.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload?.result
                state.message = action.payload?.message
            })
            .addCase(allNotificationAPI.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.error
                state.message = action.payload?.message
            })
    },
})

const allNotificationReducer = counterSlice.reducer

export default allNotificationReducer

