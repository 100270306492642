import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import HttpService from "../../config/authAxios"

const initialState = { loading: false, result: null, error: null, message: "" }

export const resetPasswordAPI = createAsyncThunk(
    'reset-password',
    async (data, { rejectWithValue }) => {
        try {
            const response = await HttpService.patch(`/users/reset-password`, data)
            return await response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }

    }
)

const counterSlice = createSlice({
    name: 'reset-password',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(resetPasswordAPI.pending, (state, action) => {
                state.loading = true
            })
            .addCase(resetPasswordAPI.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload?.result
                state.message = action.payload?.message
            })
            .addCase(resetPasswordAPI.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.error
                state.message = action.payload?.message
            })
    },
})

const resetPasswordReducer = counterSlice.reducer

export default resetPasswordReducer

