import CloseIcon from '@mui/icons-material/Close';
import { Button, Card, Grid, IconButton, Modal, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeCLBStatusAPI } from '../../redux/clb/changeCLBStatus';
import Snack from '../../ui/Snack';
import { getSIngleCLBAPI } from '../../redux/clb/getSIngleCLB';

export default function ChangeCLBStatus({ open, setOpen, CLB, active }) {
    const changeCLBStatusSelector = useSelector(state => state?.changeCLBStatus)
    const { message, error } = changeCLBStatusSelector

    const dispatch = useDispatch()



    const handleClose = () => setOpen(false)

    const handleChangeCLBStatus = async () => {
        await dispatch(changeCLBStatusAPI({ clbId: CLB?._id, active }))
        await dispatch(getSIngleCLBAPI(CLB?._id))

        setOpen(false)
    }

    return (
        <>
            <Modal open={open} onClose={handleClose} className='modal'>
                <Grid container justifyContent='center' sx={{ p: 2 }}>
                    <Grid lg={5} md={7} sm={9} xs={12} >
                        <Card>
                            <Stack spacing={2}>
                                <Stack direction='row'>
                                    <Typography align='center' variant='h5' sx={{ flex: 1 }}>{`Nft Status to ${active ? 'Active' : 'In-Active'}`}</Typography>
                                    <IconButton onClick={handleClose}><CloseIcon /></IconButton>
                                </Stack>
                                <Typography>{`By Allowing this action you CLBs will be ${active ? 'Active' : 'In-Active'} to every user!`}</Typography>
                                <Button variant='contained' fullWidth onClick={handleChangeCLBStatus}>Change Status</Button>
                            </Stack>
                        </Card>
                    </Grid>
                </Grid>
            </Modal>
        </>
    )
}
