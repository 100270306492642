import { Avatar, Typography } from "@material-ui/core";
import { Chip, Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allNotificationAPI } from "../../redux/notification/allNotification";
import LoaderUI from "../../ui/LoaderUI";

export default function AllNotification() {
  const allNotificationSelector = useSelector(
    (state) => state?.allNotification
  );
  const { result, loading } = allNotificationSelector;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allNotificationAPI());
  }, [dispatch]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
          overflow: "scroll",
          height: "calc(100vh - 50px)",
        }}
      >
        {loading ? (
          <LoaderUI />
        ) : (
          result?.length > 0 ? (

            result?.map((item, idx) => {
              const notificationDate = new Date(item?.created_at);

              return (
                <Grid
                  container
                  key={idx}
                  columnGap={1}
                  sx={{
                    bgcolor: "#484848",
                    p: 1,
                    borderRadius: 1,
                    width: "80%",
                  }}
                >
                  <Grid item xs={2} md={2} lg={2}>
                    <Avatar src={item?.userId?.picture}>
                      {item?.notificationId?.type == "Admin"
                        ? "A"
                        : item?.user?.name?.[0]}
                    </Avatar>
                  </Grid>
                  <Grid item xs={8} md={8} lg={8}>
                    <Typography>{item?.notificationId?.message}</Typography>

                    <Typography variant="caption" className="text-gray-200">
                      {notificationDate.toLocaleString()}
                    </Typography>
                  </Grid>
                </Grid>
              )
            })
          ) : (
            <p style={{marginTop: "20px"}}>No Notifications</p>
          )
        )
      }
      </div>
    </>
  );
}
