import { Avatar, Box, Container, Grid, Stack, Typography } from '@mui/material';
import { default as React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserCLBsAPI } from '../../redux/clb/getUserCLBs';
import { userFollowersAPI } from '../../redux/user/userFollowers';



const Followers = (id) => {
    const userFollowersSelector = useSelector(state => state?.userFollowers)
    const { result, loading } = userFollowersSelector

    const dispatch = useDispatch()
    const navigate = useNavigate();


    useEffect(() => {
        dispatch(userFollowersAPI(id))
    }, []);

    return (
        <>
            <Box sx={{ mt: 10 }} >
                <Typography variant='h5' align='center'>People who follow you</Typography>
                <Container sx={{ my: 4 }}>
                    <Grid container spacing={3}>
                    {console.log("CLBBBBBBBBBBBBBBBBB----------->",userFollowersSelector)}
                        {result?.map((item, idx) => {
                            return (
                                <Grid item xs={12} sm={6} md={3} lg={3} onClick={() => navigate(`/profile/${item._id}`)}>
                                    <Box className='gradBox'>
                                        <Stack direction='row' spacing={2}>
                                            <Avatar src={item?.picture} variant="rounded" sx={{ width: 100, height: 100 }} />
                                            <Box>
                                                <Typography color='primary'>{item?.name?.toUpperCase()}</Typography>
                                                <Typography>{item?.email}</Typography>
                                            </Box>
                                        </Stack>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </Box>
        </>

    );
};

export default Followers;
