import React from "react";
import styles from "./Aboutus.module.css";
const Services = (resource) => {
  return (
    <div className={styles.whowe}>
      <div style={{ textAlign: "justify", whiteSpace: 'pre-line'  }}>
        {resource?.resource?.anoutUs?.whyUs.desc}
      </div>
    </div>
  );
};
export default Services;
