import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import HttpService from "../../config/authAxios"

const initialState = { loading: false, result: null, error: null, message: "" }

export const allUnseenNotificationCountAPI = createAsyncThunk(
    'unseen-notification-count',
    async (data, { rejectWithValue }) => {
        try {
            const response = await HttpService.get(`/notification/unseen-count`)
            return await response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }

    }
)

const counterSlice = createSlice({
    name: 'unseen-notification-count',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(allUnseenNotificationCountAPI.pending, (state, action) => {
                state.loading = true
            })
            .addCase(allUnseenNotificationCountAPI.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload?.result
                state.message = action.payload?.message

                if (state.result > 0) {
                    // Set a flag in the state to indicate that the icon should be shown
                    state.showIcon = true
                } else {
                    state.showIcon = false
                }




            })
            .addCase(allUnseenNotificationCountAPI.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.error
                state.message = action.payload?.message
            })

        






    },
})

const allUnseenNotificationCountReducer = counterSlice.reducer

export default allUnseenNotificationCountReducer

