import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/header/Header";
import Style from "../../styles/profile.module.scss";
import DividerUI from "../../ui/DividerUI";
import Activities from "./Activities";
import CollectedCLBs from "./CollectedCLBs";
import CreatedCLBs from "./CreatedCLBs";
import FavoritesCLBs from "./FavoritesCLBs";
import Following from "./Following";
import Followers from "./Followers";
import TotalCLBs from "./TotalCLBs";
import { userFollowingCountAPI } from "../../redux/user/userFollowingCount";
import { userFollowersCountAPI } from "../../redux/user/userFollowersCount";
import { getCurrentUserAPI } from "../../redux/user/getCurrentUser";
import { getSelectedUserDetailsAPI } from "../../redux/user/getSelectedUserDetails";
import { useParams } from "react-router-dom";

import DEFAULT_COVER from "../../assets/png/galaxy-7040416_1280.png";

const Profile = () => {
  const { id } = useParams();

  const getSelectedUserDetailsSelector = useSelector(
    (state) => state?.getSelectedUserDetails
  );
  const { result: user } = getSelectedUserDetailsSelector;

  const userFollowingCountSelector = useSelector(
    (state) => state?.userFollowingCount
  );
  const { result: followingCount } = userFollowingCountSelector;

  const userFollowersCountSelector = useSelector(
    (state) => state?.userFollowersCount
  );
  const { result: followersCount } = userFollowersCountSelector;

  const dispatch = useDispatch();

  const [clbOpt, setClBOpt] = useState("Created");

  useEffect(() => {
    dispatch(getSelectedUserDetailsAPI(id));
    dispatch(
      userFollowingCountAPI({
        profileId: id,
      })
    );
    dispatch(
      userFollowersCountAPI({
        profileId: id,
      })
    );
  }, [id]);

  return (
    <Box className={Style.main}>
      <Header />
      <Container sx={{ mt: 4 }}>
        <Card className="transCard">
          <Stack justifyContent="center" alignItems="center">
            <Box style={{ width: "100%", height: "100%" }}>
              <img
                className={Style?.coverImg}
                src={user?.cover ?? DEFAULT_COVER}
                alt="cover image"
              />
            </Box>
            <Avatar
              className={Style?.userImage}
              // sx={{
              //   width: 200,
              //   height: 200,
              //   mt: -10,
              //   border: "2px solid #35ABAF",
              // }}
              src={user?.picture}
              alt="user image"
            />
            <Typography variant="h4">{user?.name}</Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Button
                className={
                  clbOpt == "Followers"
                    ? Style?.avtivFollowSectionBtn
                    : Style?.InAvtivFollowSectionBtn
                }
                onClick={() => setClBOpt("Followers")}
              >
                {followersCount} Followers
              </Button>
              <DividerUI bgcolor="#999999" orientation="vertical" height={10} />
              <Button
                className={
                  clbOpt == "Following"
                    ? Style?.avtivFollowSectionBtn
                    : Style?.InAvtivFollowSectionBtn
                }
                onClick={() => setClBOpt("Following")}
              >
                {followingCount} Following
              </Button>
              {/* <DividerUI bgcolor="#999999" orientation="vertical" height={10} /> */}
              {/* <Button
                className={
                  clbOpt == "CLBs"
                    ? Style?.avtivFollowSectionBtn
                    : Style?.InAvtivFollowSectionBtn
                }
                onClick={() => setClBOpt("CLBs")}
              >
                CLBs
              </Button> */}
            </Stack>
          </Stack>

          <Grid container justifyContent="center" alignItems="center">
            <Button
              className={
                clbOpt == "Created" ? Style?.avtivOptBtn : Style?.InAvtivOptBtn
              }
              onClick={() => setClBOpt("Created")}
            >
              Created
            </Button>
            <Button
              className={
                clbOpt == "Favorites"
                  ? Style?.avtivOptBtn
                  : Style?.InAvtivOptBtn
              }
              onClick={() => setClBOpt("Favorites")}
            >
              Favorites
            </Button>
            <Button
              className={
                clbOpt == "Activities"
                  ? Style?.avtivOptBtn
                  : Style?.InAvtivOptBtn
              }
              onClick={() => setClBOpt("Activities")}
            >
              Activities
            </Button>
          </Grid>

          <DividerUI />

          {/* CLBs options */}
          {/* {clbOpt == "Collected" ? <CollectedCLBs /> : null} */}
          {clbOpt == "Created" ? <CreatedCLBs /> : null}
          {clbOpt == "Favorites" ? <FavoritesCLBs /> : null}
          {clbOpt == "Activities" ? <Activities /> : null}

          {/* follow /following / CLBs */}
          {clbOpt == "Followers" ? <Followers id={id} /> : null}
          {clbOpt == "Following" ? <Following id={id} /> : null}
          {clbOpt == "CLBs" ? <TotalCLBs /> : null}
        </Card>
      </Container>
    </Box>
  );
};

export default Profile;
