import { combineReducers } from "redux";
import getClBBidDetailsReducer from "./bid/getClBBidDetails";
import placeBidReducer from "./bid/placeBid";
import getAllCartReducer from "./cart/getAllCart";
import clearCartReducer from "./category/clearCart";
import clearSingleCartReducer from "./category/clearSingleCart";
import getAllCategoryReducer from "./category/getAllCategory";
import buyCLBReducer from "./clb/buyCLB";
import changeCLBStatusReducer from "./clb/changeCLBStatus";
import createCLBReducer from "./clb/createCLB";
import getAllApprovedCLBsReducer from "./clb/getAllApprovedCLBs";
import getClbHistoryGraphReducer from "./clb/getClbHistoryGraph";
import getHotSellingClbsReducer from "./clb/getHotSellingClbs";
import getLiveCLBsReducer from "./clb/getLiveCLBs";
import getSIngleCLBReducer from "./clb/getSIngleCLB";
import getSelectedClBHistoryReducer from "./clb/getSelectedClBHistory";
import getTopBidCLBsReducer from "./clb/getTopBidCLBs";
import getTopSellerReducer from "./clb/getTopSeller";
import getUserCLBsReducer from "./clb/getUserCLBs";
import moreLikeCLBsReducer from "./clb/moreLikeCLBs";
import searchCLBsReducer from "./clb/searchCLBs";
import sellPurchasedCLBReducer from "./clb/sellPurchasedCLB";
import createCollectionReducer from "./collection/createCollection";
import getAllCollectionReducer from "./collection/getAllCollection";
import getAllHotCollectionsReducer from "./collection/getAllHotCollections";
import getTopCollectionsReducer from "./collection/getTopCollections";
import getUserCollectionReducer from "./collection/getUserCollection";
import getConfigMetaReducer from "./config/getConfigMeta";
import getExploreCLBsReducer from "./explore/getExploreCLBs";
import getPriceSliderReducer from "./explore/getPriceSlider";
import changeCLBsFavStatusReducer from "./favorite/changeCLBsFavStatus";
import getAllGalleryReducer from "./gallery/getAllGallery";
import allNotificationReducer from "./notification/allNotification";
import allUnseenNotificationCountReducer from "./notification/allUnseenNotificationCount";
import allUnseenNotificationsReducer from "./notification/allUnseenNotifications";
import getAllOrdersReducer from "./orders/getAllOrders";
import placeOrderReducer from "./orders/placeOrder";
import raiseTicketReducer from "./ticket/raiseTicket";
import addKYCReducer from "./kyc/addKYC";
import allread from "./notification/allread";
import followUserReducer from "./user/followUser";
import getCollectedCLBsReducer from "./user/getCollectedCLBs";
import getCreatedCLbsReducer from "./user/getCreatedCLbs";
import getCurrentUserReducer from "./user/getCurrentUser";
import getSelectedUserDetailsReducer from "./user/getSelectedUserDetails";
import isFollowingUserReducer from "./user/isFollowingUser";
import updateProfileReducer from "./user/updateProfile";
import userFollowersReducer from "./user/userFollowers";
import userFollowersCountReducer from "./user/userFollowersCount";
import userFollowingReducer from "./user/userFollowing";
import userFollowingCountReducer from "./user/userFollowingCount";
import verifyEmailReducer from "./user/verifyEmail";
import extandEndDateReducer from "./clb/extandEndDate";
import getfavCLBsReducer from "./user/getfavCLBs";
import singUpReducer from "./auth/singUp";
import singInReducer from "./auth/singIn";
import panCardVerificationReducer from "./kyc/panCardVerification";
import gstVerificationReducer from "./kyc/gstVerification";
import forgetPasswordReducer from "./auth/forgetPassword";
import resetPasswordReducer from "./auth/resetPassword";
import snackSliceReducer from "./ui/snack";
import blogsReducer from "./blog/blog";
import getBlogCategoriesReducer from "./blog/topCategory";
import markAsReadReducer from "./notification/allread";
import singUpOrgReducer from "./auth/singUpOrg";
import getCurrentUserOrgReducer from "./user/getCurrentUserOrg";
import deleteCurrentUserOrgDocReducer from "./user/deleteCurrentUserOrgDoc";
import assignClbReducer from "./clb/assignClb";
import placeMultipleOrdersReducer from "./orders/placeMultipleOrders";
import createOkycReducer from "./kyc/createOkyc";
import initiateOkycReducer from "./kyc/initiateOkyc";
import verifyOkycReducer from "./kyc/verifyOkyc";
import okycStepReducer from "./kyc/okycStep";
import revokeClbReducer from "./clb/revokeClb";
import getEstimatedGasFeesReducer from "./clb/estimateGasPrice";
import policyReducer from "./privacyPolicy/privacy";

const reducers = combineReducers({
  // notification
  allNotification: allNotificationReducer,
  allUnseenNotifications: allUnseenNotificationsReducer,
  allUnseenNotificationCount: allUnseenNotificationCountReducer,
  markAsRead: markAsReadReducer,
  // clbs
  createCLB: createCLBReducer,
  changeCLBStatus: changeCLBStatusReducer,
  getSelectedClBHistory: getSelectedClBHistoryReducer,
  buyCLB: buyCLBReducer,
  getClbHistoryGraph: getClbHistoryGraphReducer,
  getAllApprovedCLBs: getAllApprovedCLBsReducer,
  addKYC: addKYCReducer,
  getLiveCLBs: getLiveCLBsReducer,
  getTopBidCLBs: getTopBidCLBsReducer,
  moreLikeCLBs: moreLikeCLBsReducer,
  getTopSeller: getTopSellerReducer,
  getHotSellingClbs: getHotSellingClbsReducer,
  getSIngleCLB: getSIngleCLBReducer,
  searchCLBs: searchCLBsReducer,
  sellPurchasedCLB: sellPurchasedCLBReducer,
  assignClb: assignClbReducer,
  extandEndDate: extandEndDateReducer,
  revokeClbReducer: revokeClbReducer,
  getEstimatedGasFees: getEstimatedGasFeesReducer,
  // Bid
  placeBid: placeBidReducer,
  getClBBidDetails: getClBBidDetailsReducer,
  // category
  getAllCategory: getAllCategoryReducer,
  // collection
  getAllCollection: getAllCollectionReducer,
  getAllHotCollections: getAllHotCollectionsReducer,
  getTopCollections: getTopCollectionsReducer,
  createCollection: createCollectionReducer,
  getUserCollection: getUserCollectionReducer,
  // orders
  getAllOrders: getAllOrdersReducer,
  placeOrder: placeOrderReducer,
  placeMultipleOrders: placeMultipleOrdersReducer,
  // user
  getCurrentUser: getCurrentUserReducer,
  getCurrentUserOrg: getCurrentUserOrgReducer,
  deleteCurrentUserOrgDoc: deleteCurrentUserOrgDocReducer,
  getUserCLBs: getUserCLBsReducer,
  updateProfile: updateProfileReducer,
  userFollowers: userFollowersReducer,
  userFollowersCount: userFollowersCountReducer,
  userFollowing: userFollowingReducer,
  userFollowingCount: userFollowingCountReducer,
  getSelectedUserDetails: getSelectedUserDetailsReducer,
  followUser: followUserReducer,
  isFollowingUser: isFollowingUserReducer,
  verifyEmail: verifyEmailReducer,
  getCollectedCLBs: getCollectedCLBsReducer,
  getCreatedCLbs: getCreatedCLbsReducer,
  getfavCLBs: getfavCLBsReducer,
  forgetPassword: forgetPasswordReducer,
  resetPassword: resetPasswordReducer,
  blogs: blogsReducer,
  blogTopCategories: getBlogCategoriesReducer,
  // auth
  singUp: singUpReducer,
  singIn: singInReducer,
  singUpOrg: singUpOrgReducer,
  // config
  getConfigMeta: getConfigMetaReducer,
  // explore
  getExploreCLBs: getExploreCLBsReducer,
  getPriceSlider: getPriceSliderReducer,
  // gallery
  getAllGallery: getAllGalleryReducer,
  // favorite
  changeCLBsFavStatus: changeCLBsFavStatusReducer,
  // cart
  getAllCart: getAllCartReducer,
  clearCart: clearCartReducer,
  clearSingleCart: clearSingleCartReducer,
  // ticket
  raiseTicket: raiseTicketReducer,
  // verification
  okycStepReducer: okycStepReducer,
  panCardVerification: panCardVerificationReducer,
  gstVerification: gstVerificationReducer,
  createOkycReducer: createOkycReducer,
  initiateOkycReducer: initiateOkycReducer,
  verifyOkycReducer: verifyOkycReducer,

  // non syncronouse ---------->
  snack: snackSliceReducer,

  // policy
  policy : policyReducer
});

export default reducers;
