import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createOkycAPI } from "./createOkyc";
import { initiateOkycAPI } from "./initiateOkyc";
import { verifyOkycAPI } from "./verifyOkyc";
import { completeOkycAPI } from "./completeOkyc";

const initialState = { step: 0 };

export const resetStepState = createAsyncThunk("okyc-step", async () => {
  return true;
});

const counterSlice = createSlice({
  name: "okyc-step",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createOkycAPI.fulfilled, (state) => {
        state.step = 0;
        state.retry = false;
      })
      .addCase(initiateOkycAPI.fulfilled, (state) => {
        state.step = 1;
      })
      .addCase(verifyOkycAPI.fulfilled, (state) => {
        state.step = 2;
      })
      .addCase(completeOkycAPI.fulfilled, (state) => {
        state.step = 3;
      })
      .addCase(resetStepState, (state) => {
        state.step = 0;
      });
  },
});

const okycStepReducer = counterSlice.reducer;

export default okycStepReducer;
