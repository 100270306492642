import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpService from "../../config/authAxios";
import { useNavigate } from "react-router-dom";

const initialState = { loading: false, result: null, error: null, message: "" };

export const singInAPI = createAsyncThunk(
  "sign-in",
  async (data, { rejectWithValue }) => {
    // console.log("data", data);
    try {
      // const navigate = useNavigate();
      const response = await HttpService.post(`/users/signUp`, data);
      // alert("123123");
      // console.log(response.data);
      // Navigate({ to: "/signin" });
      // console.log("got response", response.data);
      return await response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const counterSlice = createSlice({
  name: "sign-in",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(singInAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(singInAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload?.result;
        state.message = "Signup Successfully, Please verify your email";
      })
      .addCase(singInAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        state.message = action.payload?.message;
      });
  },
});

const singInReducer = counterSlice.reducer;

export default singInReducer;
